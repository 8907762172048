import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { timer } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  public source = "";
  public userId = "";
  
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.matIconRegistry.addSvgIcon(
      `ABARTH`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../assets/icons/ABARTH.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `ALFA`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../assets/icons/ALFA.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `CITROEN`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../assets/icons/CITROEN.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `DODGE`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../assets/icons/DODGE.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `DS`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../assets/icons/DS.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `FIAT 2020`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../assets/icons/FIAT 2020.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `FIAT PRO`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../assets/icons/FIAT PRO.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `JEEP`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../assets/icons/JEEP.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `LANCIA`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../assets/icons/LANCIA.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `OPEL`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../assets/icons/OPEL.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `PEUGEOT`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../assets/icons/PEUGEOT.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `RAM`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../assets/icons/RAM.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `VAUXHALL`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../assets/icons/VAUXHALL.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `HORI`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../assets/icons/HORI.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `CHRYSLER`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../assets/icons/CHRYSLER.svg")
    );
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const source = params['Source'];

      if (source) {
        this.source = source;
      }
    });

    const interval = 1000;

    timer(0, interval).pipe(
      switchMap(() => {
        const userId = sessionStorage.getItem('userId');
        return [userId];
      }),
      takeWhile(userId => userId === null, true)
    ).subscribe(userId => {
      if (userId !== null) {
        this.userId = userId;
      }
    });
  }

  
}
