<div class="header">
  <div class="row d-flex align-items-center p-2">
    <div class="ps-5" style="width: auto; height:4.5rem;">
      <mat-icon svgIcon="HORI" class="icon"></mat-icon>
    </div>
    <div class="col" *ngIf="source == 'Hub'" style="border-left: 1px solid white; height:4.5rem;">
      <mat-icon svgIcon="DS" class="brand"></mat-icon>
      <mat-icon svgIcon="CITROEN" class="brand"></mat-icon>
      <mat-icon svgIcon="OPEL" class="brand"></mat-icon>
      <mat-icon svgIcon="PEUGEOT" class="brand"></mat-icon>
      <mat-icon svgIcon="VAUXHALL" class="brand"></mat-icon>
    </div>
    <div class="col" *ngIf="source == 'LinkEntry'" style="border-left: 1px solid white; height:4.5rem;">
      <mat-icon svgIcon="FIAT 2020" class="brand"></mat-icon>
      <mat-icon svgIcon="FIAT PRO" class="brand"></mat-icon>
      <mat-icon svgIcon="ABARTH" class="brand"></mat-icon>
      <mat-icon svgIcon="ALFA" class="brand"></mat-icon>
      <mat-icon svgIcon="JEEP" class="brand"></mat-icon>
      <mat-icon svgIcon="LANCIA" class="brand"></mat-icon>
      <mat-icon svgIcon="RAM" class="brand"></mat-icon>
      <mat-icon svgIcon="DODGE" class="brand"></mat-icon>
    </div>
    <div class="col" *ngIf="source == 'NA'" style="border-left: 1px solid white; height:4.5rem;">
      <mat-icon svgIcon="FIAT 2020" class="brand"></mat-icon>
      <mat-icon svgIcon="FIAT PRO" class="brand"></mat-icon>
      <mat-icon svgIcon="ABARTH" class="brand"></mat-icon>
      <mat-icon svgIcon="ALFA" class="brand"></mat-icon>
      <mat-icon svgIcon="JEEP" class="brand"></mat-icon>
      <mat-icon svgIcon="LANCIA" class="brand"></mat-icon>
      <mat-icon svgIcon="RAM" class="brand"></mat-icon>
      <mat-icon svgIcon="DODGE" class="brand"></mat-icon>
      <mat-icon svgIcon="PEUGEOT" class="brand"></mat-icon>
      <!-- <mat-icon svgIcon="CHRYSLER" class="brand"></mat-icon> -->
    </div>
    <div class="col d-flex justify-content-end pe-5">
      <app-language-selection></app-language-selection>
      <app-profile [userId]="userId"></app-profile>
    </div>
  </div>
</div>