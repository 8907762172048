import { Component, OnDestroy } from '@angular/core';
import { FormCustomize } from '../../model/Form/FormCustomize';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api/api.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ErrorPopupComponent } from '../../popup/error-popup/error-popup.component';
import { itemSubscriptionForm } from './item-subscription-form';
import {
  ItemDocuments,
  SubscribeItemListReq,
  SubscribeItemListResp,
} from '../../model/item-subscribe/subscribe';

@Component({
  selector: 'app-item-subscription',
  templateUrl: './item-subscription.component.html',
  styleUrl: './item-subscription.component.scss',
})
export class ItemSubscriptionComponent implements OnDestroy {
  public formCustomize: FormCustomize[] = itemSubscriptionForm;
  public allDocuments!: SubscribeItemListResp | null;
  private source!: string;
  private token!: string;
  private tokenParam!: string;
  public formGroup!: FormGroup;
  public searchSpinner: boolean = false;
  public userId!: string;
  public noDocuments: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private dialog: MatDialog,
    private router: Router,
    public translate: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.api
      .resetSubscribedItemsCounter(sessionStorage.getItem('userId')!)
      .subscribe();
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const token = params['token'];
      const tokenParam = params['tokenParam'];
      const source = params['Source'];

      this.translate.use(sessionStorage.getItem('language') ?? 'en-GB');
      if (!sessionStorage.getItem('sessionId')) {
        const queryParams = this.route.snapshot.queryParams;
        this.router.navigate(['digitaltechassistance/request/search'], {
          queryParams,
        });
      }

      if (source) {
        this.source = source;
      }
      if (token) {
        this.token = token;
      }
      if (source == 'LinkEntry' && tokenParam) {
        this.tokenParam = tokenParam;
        sessionStorage.setItem('tokenParam', tokenParam);
      }

      if (!source || !token || (source == 'LinkEntry' && !tokenParam)) {
        this.router.navigateByUrl('/error');
        return;
      }

      this.api
        .jwtValidate(this.source, [
          this.token,
          this.source == 'LinkEntry' ||
          this.source == 'POI-XP' ||
          this.source == 'POI-XF'
            ? this.tokenParam
            : null,
        ])
        .subscribe(
          (response: any) => {
            switch (this.source) {
              case 'Hub':
                this.userId = response.userId;
                break;
              case 'LinkEntry':
                this.userId = response.UserID;
                break;
              case 'POI-XP':
                this.userId = response.userID;
                break;
              case 'POI-XF':
                this.userId = response.userID;
                break;
            }
            this.initializeForm();
            this.askDTA();
          },
          (error: any) => {
            this.router.navigateByUrl('/error');
          }
        );
    });
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      vinWithoutValidation: [''],
      itemNumber: [''],
    });
  }

  askDTA() {
    this.allDocuments = null;
    this.noDocuments = false;
    const req: SubscribeItemListReq = {
      userId: sessionStorage.getItem('userId')!,
      itemNumber:
        this.formGroup.get('itemNumber')?.value == ''
          ? null
          : this.formGroup.get('itemNumber')?.value,
      vinVis:
        this.formGroup.get('vinWithoutValidation')?.value == ''
          ? null
          : this.formGroup.get('vinWithoutValidation')?.value,
      jwt: sessionStorage.getItem('jwt')!,
    };

    this.searchSpinner = true;
    this.api.getSubscribedList(req).subscribe(
      (resp: any) => {
        if (resp.documents.length == 0) this.noDocuments = true;
        this.allDocuments = resp;
        this.allDocuments!.documents.forEach(
          (document: ItemDocuments) => (document.selected = false)
        );
        const priorityItemNumbers = JSON.parse(
          sessionStorage.getItem('itemsUpdated')!
        );
        this.allDocuments!.documents.sort(
          (a: ItemDocuments, b: ItemDocuments) => {
            return (
              new Date(b.subscriptionDate).getTime() -
              new Date(a.subscriptionDate).getTime()
            );
          }
        );
        this.allDocuments!.documents.sort(
          (a: ItemDocuments, b: ItemDocuments) => {
            const isAPriority = priorityItemNumbers.items.includes(
              a.itemNumber
            );
            const isBPriority = priorityItemNumbers.items.includes(
              b.itemNumber
            );

            if (isAPriority && !isBPriority) return -1;
            if (!isAPriority && isBPriority) return 1;

            return 0;
          }
        );
        this.searchSpinner = false;
      },
      (err: any) => {
        let error = {
          statusCode: err.error.statusCode,
          url: err.error.url.replace('uri=/aiengine/api/', ''),
          details: err.error.details,
          message: "Couldn't retrieve the subscribed items",
        };
        this.errorPopup(error);
        this.allDocuments = null;
        this.searchSpinner = false;
        this.noDocuments = true;
      }
    );
  }

  errorPopup(error: any) {
    this.dialog.open(ErrorPopupComponent, {
      disableClose: true,
      data: error,
    });
  }
}
